import { useEffect, useRef } from "react"
import styled from "styled-components"

import {
  loginMenuOpenAni,
  loginMenuCloseAni,
} from "../../animations/navigation"
import { focusStyles } from "../../styles/siteStyles"

interface LoginMoreMenuProps {
  isNavOpen: boolean
  toggleNavMenu: () => void
}

const MenuContainer = styled.button`
  padding: 6px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  background: var(--color-purple-15);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: box-shadow 300ms ease-in-out;
  &:focus {
    ${focusStyles}
  }
  &:hover {
    box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4),
      var(--gradient-button-hover-blue-1);
  }
`

const MoreContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  height: 100%;
`

const MenuDot = styled.div`
  background: var(--color-blue-1);
  border-radius: 50%;
  width: 7px;
  height: 7px;
`

const CloseContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  opacity: 0;
`

const XCross = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background: var(--color-blue-1);
  border-radius: 20px;
  width: 5px;
  height: 100%;
  opacity: 0;
`

export const LoginMoreMenuIcon: React.FC<LoginMoreMenuProps> = ({
  isNavOpen,
  toggleNavMenu,
}) => {
  const dotContainerRef = useRef<HTMLDivElement | null>(null)
  const dot1Ref = useRef<HTMLDivElement | null>(null)
  const dot2Ref = useRef<HTMLDivElement | null>(null)
  const dot3Ref = useRef<HTMLDivElement | null>(null)
  const closeContainerRef = useRef<HTMLDivElement | null>(null)
  const leftCrossRef = useRef<HTMLDivElement | null>(null)
  const rightCrossRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const dotContainer = dotContainerRef.current
    const dot1 = dot1Ref.current
    const dot2 = dot2Ref.current
    const dot3 = dot3Ref.current
    const closeContainer = closeContainerRef.current
    const leftCross = leftCrossRef.current
    const rightCross = rightCrossRef.current

    if (
      dotContainer &&
      dot1 &&
      dot2 &&
      dot3 &&
      closeContainer &&
      leftCross &&
      rightCross &&
      isNavOpen
    ) {
      loginMenuOpenAni(
        dotContainer,
        dot1,
        dot2,
        dot3,
        closeContainer,
        leftCross,
        rightCross,
        false
      )
    }

    if (
      dotContainer &&
      dot1 &&
      dot2 &&
      dot3 &&
      closeContainer &&
      leftCross &&
      rightCross &&
      !isNavOpen
    ) {
      loginMenuCloseAni(
        dotContainer,
        dot1,
        dot2,
        dot3,
        closeContainer,
        leftCross,
        rightCross,
        false
      )
    }
  }, [isNavOpen])

  return (
    <MenuContainer
      aria-label="Not logged in navigation menu"
      type="button"
      onClick={toggleNavMenu}
    >
      <MoreContainer ref={dotContainerRef}>
        <MenuDot ref={dot1Ref} />
        <MenuDot ref={dot2Ref} />
        <MenuDot ref={dot3Ref} />
      </MoreContainer>
      <CloseContainer ref={closeContainerRef}>
        <XCross ref={leftCrossRef} />
        <XCross ref={rightCrossRef} />
      </CloseContainer>
    </MenuContainer>
  )
}
