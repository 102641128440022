import styled from "styled-components"

import { NavItem } from "./NavItem"
import { LoginNavigationTransition } from "../../../animations/transitions/LoginViewNavigationTransition"
import { appLoginNavList } from "../../../data/navigation"
import { sizes } from "../../../styles/sizes"

interface NavDrawerProps {
  isOpen: boolean
  toggleNavMenu: () => void
}

const DrawerContainer = styled.div`
  position: absolute;
  left: 70px;
  bottom: 16px;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  background: var(--gradient-purple1-2);
  border-radius: 12px;
  width: 180px;
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.4);
  ${sizes.aboveMobile} {
    left: unset;
    right: 70px;
  }
`

export const NavDrawer: React.FC<NavDrawerProps> = ({
  isOpen,
  toggleNavMenu,
}) => {
  const navList = appLoginNavList.map((item) => {
    const id = item.id
    const label = item.label
    const url = item.url

    return (
      <NavItem key={id} url={url} toggleNavMenu={toggleNavMenu}>
        {label}
      </NavItem>
    )
  })

  return (
    <LoginNavigationTransition isOpen={isOpen}>
      <DrawerContainer>{navList}</DrawerContainer>
    </LoginNavigationTransition>
  )
}
