import { BaseSVGProps } from "../../types";

export const RealKettlebellIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 163 196"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        id="real-kettlebell-icon-container"
        fill="none"
        d="M0 0h162.5v195.898H0z"
      />
      <path
        style={{ fill: "var(--real-kettlebell-fill, var(--color-white-1))" }}
        id="kettlebell-equipment"
        d="M22.924 112.787c1.083-4.237 1.309-6.13-2.347-9.741C8.646 91.26 1.783 65.311 2.117 51.79c.177-7.15 3.154-27.86 20.665-38.045C44.586 1.064 69.038 1.488 81.302 1.488c11.757 0 42.177 2.037 57.96 12.403 17.153 11.265 20.795 30.647 21.298 38.067 1.067 15.769-5.772 41.831-17.669 51.798-2.632 2.205-3.452 4.224-4.013 7.213a64.638 64.638 0 018.29 24.386c2.768 21.871-4.282 47.389-18.627 57.421-1.835 1.284-30 1.058-43.415 1.058-15.386 0-48.813.08-49.83-.681-15.154-11.347-20.812-37.258-20.435-51.264.291-10.812 3.238-20.623 8.063-29.102zm58.601 4.092c14.929 0 27.05 12.12 27.05 27.049s-12.121 27.05-27.05 27.05-27.049-12.121-27.049-27.05 12.12-27.049 27.049-27.049zm36.531-27.531c3.105-2.62 5.831-5.546 7.959-8.701 8.882-13.167 10.355-26.827 4.218-37.261-6.189-10.521-24.987-15.118-48.846-15.118-23.859 0-40.536 2.909-47.834 11.846-6.469 7.923-6.659 27.521 4.5 41.891 2.13 2.744 4.743 5.197 7.664 7.371C56.033 83 67.96 79.38 79.894 79.191c15.013-.237 27.84 3.642 38.162 10.157z"
      />
      <circle
        style={{ fill: "var(--real-kettlebell-fill, var(--color-white-1))" }}
        id="middle-circle"
        cx="81.387"
        cy="143.928"
        r="23.759"
      />
    </svg>
  );
};
