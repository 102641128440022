import { gsap, Power3 } from "gsap";

export const circleToggleSwitchOnAni = (toggle: HTMLSpanElement) => {
  gsap.to(toggle, { duration: 0.3, x: 45, ease: Power3.easeIn });
};

export const circleToggleSwitchOffAni = (toggle: HTMLSpanElement) => {
  gsap.to(toggle, { duration: 0.3, x: 0, ease: Power3.easeIn });
};

export const largeToggleSwithWithWordsOnAni = (
  knob: HTMLSpanElement,
  xDistance: number
) => {
  gsap.to(knob, { duration: 0.3, x: xDistance, ease: Power3.easeOut });
};

export const largeToggleSwithWithWordsOffAni = (knob: HTMLSpanElement) => {
  gsap.to(knob, { duration: 0.3, x: 0, ease: Power3.easeOut });
};
