import { useEffect, useRef, CSSProperties } from "react";
import styled from "styled-components";

import {
  largeToggleSwithWithWordsOnAni,
  largeToggleSwithWithWordsOffAni,
} from "../../../animations/switches";
import { largeToggleDarkTheme } from "../../../styles/themes/formThemes";

interface LargeToggleSwitchProps {
  name: string;
  isOn: boolean;
  leftLabel: string;
  rightLabel: string;
  toggleSwitch: () => void;
  toggleSwitchWithKeyboard: (event: React.KeyboardEvent) => void;
}

const Label = styled.label`
  --switch-width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--switch-background-color);
  border-radius: 16px;
  width: var(--switch-width);
  height: calc(var(--switch-width) / 5);
  cursor: pointer;
  outline: none;
  transition: box-shadow 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 0 4px var(--color-blue-10), 0 0 0 6px var(--color-blue-3);
  }
  &:focus {
    box-shadow: 0 0 0 4px var(--color-blue-10), 0 0 0 6px var(--color-blue-3);
  }
`;

const Switch = styled.span`
  position: absolute;
  top: 50%;
  left: 8px;
  background: var(--switch-knob-color);
  border-radius: 10px;
  width: 150px;
  height: 44px;
  transform: translateY(-50%);
`;

const SwitchLabelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: 100%;
  z-index: 1;
`;

const SwitchLabel = styled.p`
  align-self: center;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--switch-label-color);
  /* text-shadow: var(--switch-label-text-shadow); */
  transition: color, text-shadow, 240ms ease-in;
`;

const Input = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const LargeToggleSwitchWithWords: React.FC<LargeToggleSwitchProps> = ({
  name,
  isOn,
  leftLabel,
  rightLabel,
  toggleSwitch,
  toggleSwitchWithKeyboard,
}) => {
  const knobRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const knob = knobRef.current;
    const xDistance = 150 - 16;

    if (knob && isOn) {
      largeToggleSwithWithWordsOnAni(knob, xDistance);
    }

    if (knob && !isOn) {
      largeToggleSwithWithWordsOffAni(knob);
    }
  }, [isOn]);

  const switchLabelOnStyles = {
    "--switch-label-color": "var(--color-purple-2)",
    "--switch-label-text-shadow":
      "0 0 2px hsla(228, 100%, 70%, 100%), 0 0 4px hsla(228, 100%, 81%, 100%)",
  } as CSSProperties;

  const switchLabelOffStyles = {
    "--switch-label-color": "var(--color-purple-14)",
    "--switch-label-text-shadow":
      "0 0 2px hsla(228, 100%, 70%, 0%), 0 0 4px hsla(228, 100%, 81%, 0%)",
  } as CSSProperties;

  return (
    <Label
      style={largeToggleDarkTheme}
      htmlFor={name}
      tabIndex={0}
      onKeyDown={toggleSwitchWithKeyboard}
    >
      <SwitchLabelContainer>
        <SwitchLabel style={isOn ? switchLabelOffStyles : switchLabelOnStyles}>
          {leftLabel}
        </SwitchLabel>
        <SwitchLabel style={isOn ? switchLabelOnStyles : switchLabelOffStyles}>
          {rightLabel}
        </SwitchLabel>
      </SwitchLabelContainer>
      <Input
        type="checkbox"
        id={name}
        name={name}
        checked={isOn}
        onChange={toggleSwitch}
      />
      <Switch ref={knobRef} />
    </Label>
  );
};
