import { BaseSVGProps } from "../../types";

export const AppleIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 160 191"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path id="apple-logo-container" fill="none" d="M0 0h159.088v190.101H0z" />
      <path
        style={{ fill: "var(--apple-logo-fill, hsl(0, 0%, 18%))" }}
        id="apple"
        d="M157.64 137.908c-5.757 18.939-17.665 37.087-30.265 46.122-14.975 10.738-26.043-3.187-45.635-3.187-16.923 0-25.32 14.033-42.274 3.395-17.089-10.722-31.373-39.919-36.287-64.436-4.818-24.035 1.161-53.989 18.197-66.553C46.033 35.063 66.26 51.3 81.131 51.141c16.911-.181 33.578-16.294 60.802.2 4.239 2.568 7.623 6.567 10.302 11.482-10.681 7.674-19.819 21.456-19.819 37.007 0 18.113 12.455 32.291 25.224 38.078z"
      />
      <path
        style={{ fill: "var(--apple-logo-fill, hsl(0, 0%, 18%))" }}
        id="leaf"
        d="M115.283 1.785s3.483 13.353-8.68 30.471C94.845 48.804 78.11 47.037 78.11 47.037s-.104-16.65 8.99-28.903C97.942 3.526 115.283 1.785 115.283 1.785z"
      />
    </svg>
  );
};
