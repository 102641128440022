import { CSSProperties } from "react";
import styled from "styled-components";

import { ColorTheme, SectionTheme } from "../../../types";

interface FormButtonProps {
  isValid: boolean;
  buttonTheme: ColorTheme;
  sectionTheme: SectionTheme;
}

const ButtonContainer = styled.button`
  position: relative;
  margin: 0;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--button-background);
  border: none;
  border-radius: 16px;
  color: var(--color-white-1);
  font-size: 1.8rem;
  font-weight: 700;
  width: 300px;
  height: 58px;
  cursor: var(--button-cursor);
  outline: none;
  transition: box-shadow 300ms ease-in-out;
  z-index: 1;
  &:focus {
    box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4), var(--button-hover);
  }
  &:hover {
    box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4), var(--button-hover);
  }
`;

export const FormButton: React.FC<FormButtonProps> = ({
  children,
  isValid,
  buttonTheme,
  sectionTheme,
}) => {
  const blueStyle = {
    "--button-background": isValid
      ? "var(--gradient-blue-2)"
      : "var(--form-button-background-invalid)",
    "--button-cursor": isValid ? "pointer" : "not-allowed",
    "--button-hover":
      sectionTheme === "fitness"
        ? "var(--gradient-button-hover-purple-1)"
        : "var(--gradient-button-hover-purple-2)",
  } as CSSProperties;

  const pinkStyle = {
    "--button-background": isValid
      ? "var(--gradient-pink-2)"
      : "var(--form-button-background-invalid)",
    "--button-cursor": isValid ? "pointer" : "not-allowed",
    "--button-hover":
      sectionTheme === "fitness"
        ? "var(--gradient-button-hover-blue-1)"
        : "var(--gradient-button-hover-blue-2)",
  } as CSSProperties;

  const purpleStyle = {
    "--button-background": isValid
      ? "var(--gradient-purple1-2)"
      : "var(--form-button-background-invalid)",
    "--button-cursor": isValid ? "pointer" : "not-allowed",
    "--button-hover":
      sectionTheme === "fitness"
        ? "var(--gradient-button-hover-blue-1)"
        : "var(--gradient-button-hover-blue-2)",
  } as CSSProperties;

  const activeStyle =
    buttonTheme === "blue"
      ? blueStyle
      : buttonTheme === "pink"
      ? pinkStyle
      : purpleStyle;

  return (
    <ButtonContainer style={activeStyle} type="submit" disabled={!isValid}>
      {children}
    </ButtonContainer>
  );
};
