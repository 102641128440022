import { BaseSVGProps } from "../../types";

export const DashboardIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 200 196"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
    >
      <path id="dashboard-icon-container" fill="none" d="M0 0h200v195.14H0z" />
      <path
        style={{ stroke: "var(--dashboard-stroke, var(--color-purple-17))" }}
        id="vertical-panel"
        d="M93.391 8.277H48.11A39.975 39.975 0 0 0 8.137 48.251v98.877c0 22.091 17.909 40 40 40h45.254V8.277Z"
        fill="none"
        strokeWidth="12.5"
      />
      <path
        style={{ stroke: "var(--dashboard-stroke, var(--color-purple-17))" }}
        id="top-short-panel"
        d="M192.025 48.277a40 40 0 0 0-40-40H93.391v89.362a12 12 0 0 0 12 12h86.634V48.277Z"
        fill="none"
        strokeWidth="12.5"
      />
      <path
        style={{ stroke: "var(--dashboard-stroke, var(--color-purple-17))" }}
        id="bottom-short-panel"
        d="M192.025 109.639h-86.634a12.002 12.002 0 0 0-12 12v65.489h58.634a39.996 39.996 0 0 0 28.284-11.716 39.996 39.996 0 0 0 11.716-28.284v-37.489Z"
        fill="none"
        strokeWidth="12.5"
      />
    </svg>
  );
};
