import styled from "styled-components";

import { EmailForm } from "./EmailForm";
import { SocialLogin } from "../SocialLoginSection";
import { MultiDirectionWithScaleTransition } from "../../../../../animations/transitions/MultiDirectionWithScaleTransition";
import { sizes } from "../../../../../styles/sizes";

interface SignUpFormProps {
  isActive: boolean;
}

const FormContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  padding: 60px 12px 0 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 80px;
  justify-items: center;
  width: 100%;
  ${sizes.aboveMobile} {
    padding: 80px 0 0 0;
  }
`;

export const SignUpForm: React.FC<SignUpFormProps> = ({ isActive }) => {
  return (
    <MultiDirectionWithScaleTransition
      isActive={isActive}
      enterDirection="from_right"
      exitDirection="to_right"
    >
      <FormContainer>
        <EmailForm />
        <SocialLogin />
      </FormContainer>
    </MultiDirectionWithScaleTransition>
  );
};
