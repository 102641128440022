import { useState } from "react"
import styled from "styled-components"

import { KettlebellIcon } from "../../svgs/KettlebellIcon"

interface NavItemProps {
  url: string
  toggleNavMenu: () => void
}

const NavListILink = styled.a`
  display: grid;
  grid-template-columns: min-content 1fr;
  justify-items: start;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  width: 100%;
`

const NavKettlebell = styled(KettlebellIcon)`
  width: 12px;
`

const NavLabel = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-white-1);
  pointer-events: none;
`

export const NavItem: React.FC<NavItemProps> = ({
  url,
  toggleNavMenu,
  children,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    toggleNavMenu()
    if (window) {
      window.open(url, "_blank")
    }
  }

  const toggleIsHovering = () => setIsHovering((preValue) => !preValue)

  return (
    <NavListILink
      href={url}
      onClick={(e) => handleNavClick(e)}
      target="_blank"
      rel="noopener noreferrer"
      onMouseOver={toggleIsHovering}
      onMouseLeave={toggleIsHovering}
    >
      <NavKettlebell isHovering={isHovering} />
      <NavLabel>{children}</NavLabel>
    </NavListILink>
  )
}
