import styled from "styled-components"

import { RenderIcon } from "./Icons/RenderIcon"
import { focusStyles } from "../../../styles/siteStyles"
import { SocialIcon } from "../../../types"

interface SocialLoginButtonProps {
  socialIcon: SocialIcon
  handleSocialLogin: () => void
}

const SocialButton = styled.button`
  padding: 8px 14px;
  display: grid;
  grid-template-columns: min-content 1fr;
  justify-items: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-purple-15);
  background: var(--color-white-1);
  border: none;
  border-radius: 12px;
  width: 300px;
  cursor: pointer;
  transition: box-shadow 300ms ease-in-out;
  &:focus {
    ${focusStyles}
  }
  &:hover {
    box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4),
      var(--gradient-button-hover-blue-1);
  }
`

export const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({
  socialIcon,
  handleSocialLogin,
  children,
}) => {
  return (
    <SocialButton type="button" onClick={handleSocialLogin}>
      <RenderIcon socialIcon={socialIcon} />
      {children}
    </SocialButton>
  )
}
