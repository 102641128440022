import { CSSProperties } from "react";

export const fitnessDarkTheme = {
  "--section-background": "none",
  "--pre-headline": "var(--color-blue-1)",
  "--solid-headline": "var(--color-blue-1)",
  "--gradient-headline": "var(--gradient-attention-2)",
  "--gradient-list-headline": "var(--gradient-attention-3)",
  "--paragraph": "var(--color-blue-1)",
  "--strong": "var(--color-purple-5)",
  "--a-tag": "var(--color-blue-4)",
  "--button-purple-hover": "var(--gradient-button-hover-blue-1)",
  "--button-blue-hover": "var(--gradient-button-hover-purple-1)",
  "--button-pink-hover": "var(--gradient-button-hover-blue-1)",
  "--button-focus-outline": "var(--color-blue-3)",
  "--video-background": "var(--color-blue-12)",
  "--stat-chip-background": "var(--color-purple-16)",
} as CSSProperties;

export const nutritionLightTheme = {
  "--section-background": "var(--gradient-purple2-2)",
  "--pre-headline": "var(--color-purple-15)",
  "--solid-headline": "var(--color-purple-15)",
  "--gradient-headline": "var(--gradient-attention-2)",
  "--gradient-list-headline": "var(--gradient-attention-1)",
  "--paragraph": "var(--color-purple-15)",
  "--strong": "var(--color-purple-9)",
  "--a-tag": "var(--color-purple-7)",
  "--button-purple-hover": "var(--gradient-button-hover-blue-2)",
  "--button-blue-hover": "var(--gradient-button-hover-purple-2)",
  "--button-pink-hover": "var(--gradient-button-hover-blue-2)",
  "--button-focus-outline": "var(--color-purple-10)",
  "--video-background": "var(--color-white-2)",
  "--stat-chip-background": "var(--color-purple-11)",
} as CSSProperties;