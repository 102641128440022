import { useEffect, useRef, CSSProperties } from "react";
import styled from "styled-components";

import { OverlayCloseButton } from "../buttons/OverlayCloseButton";
import { FunctionalOverlayTransition } from "../../animations/transitions/FunctionalOverlayTransition";
import {
  drawerOpenFromRightAni,
  drawerClosedToRightAni,
} from "../../animations/overlays";
import { SectionTheme } from "../../types";
import { sizes } from "../../styles/sizes";

interface FunctionalOverlayProps {
  theme: SectionTheme;
  isOpen: boolean;
  toggleOverlay: () => void;
}

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: transparent;
`;

const ClickLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DrawerContainer = styled.nav`
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--drawer-background);
  border-radius: 20px;
  box-shadow: var(--drawer-border);
  outline: none;
  overflow: hidden;
  backdrop-filter: blur(4px);
  transform: translateX(108%);
  transition: background-color 300ms ease-in-out;
  isolation: isolate;
  &:focus {
    box-shadow: 0 0 0 8px rgba(125, 131, 255, 0.4);
  }
  ${sizes.aboveMobile} {
    left: unset;
    width: 380px;
  }
`;

export const FunctionalOverlay: React.FC<FunctionalOverlayProps> = ({
  theme,
  isOpen,
  toggleOverlay,
  children,
}) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const drawer = drawerRef.current;

    if (drawer && isOpen) {
      drawerOpenFromRightAni(drawer);
    }

    if (drawer && !isOpen) {
      drawerClosedToRightAni(drawer);
    }
  }, [isOpen]);

  const drawerStyle = {
    "--drawer-background":
      theme === "fitness"
        ? "hsla(240, 14%, 10%, 0.8)"
        : "hsla(240, 100%, 97%, 0.8)",
    "--drawer-border":
      theme === "fitness"
        ? "0 0 0 8px hsla(240, 100%, 0%, 0.4)"
        : "0 0 0 8px hsla(240, 28%, 50%, 0.4)",
    "--paragraph-color":
      theme === "fitness" ? "var(--color-blue-1" : "var(--color-purple-16)",
  } as CSSProperties;

  return (
    <FunctionalOverlayTransition isOpen={isOpen}>
      <BackgroundOverlay>
        <ClickLayer onClick={toggleOverlay} />
        <DrawerContainer ref={drawerRef} style={drawerStyle} tabIndex={0}>
          <OverlayCloseButton isOpen={isOpen} toggleOverlay={toggleOverlay} />
          {children}
        </DrawerContainer>
      </BackgroundOverlay>
    </FunctionalOverlayTransition>
  );
};
