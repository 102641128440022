import React, { useState, CSSProperties } from "react";
import styled from "styled-components";

import { FWWLogo } from "../../../../svgs/FWWLogo";
import { LargeToggleSwitchWithWords as ToggleSwitch } from "../../../../forms/inputs/LargeToggleSwitchWithWords";
import { InformationButton } from "../../../../buttons/InformationButton";
import { HeadlineAndInformation } from "../../../../overlays/contentTemplates/HeadlineAndInformation";
import { whyPasswordless } from "../../../../../data/infoDrops";
import { FunctionalOverlay } from "../../../../overlays/FunctionalOverlay";
import { Portal } from "../../../../shared/Portal";
import { sizes } from "../../../../../styles/sizes";

interface HeaderSectionProps {
  isSignUp: boolean;
  handleToggleSwich: () => void;
  handleToggleSwitchWithKeyboard: (e: React.KeyboardEvent) => void;
}

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  justify-items: center;
  width: 280px;
`;

const Logo = styled(FWWLogo)`
  width: 120px;
  ${sizes.aboveMobile} {
    width: 180px;
  }
`;

export const HeaderSection: React.FC<HeaderSectionProps> = ({
  isSignUp,
  handleToggleSwich,
  handleToggleSwitchWithKeyboard,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  const toggleOverlay = () => {
    setIsOverlayOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <HeaderContainer>
        <Logo gradientId="loginPageHeader" />
        <ToggleSwitch
          name="loginOrSignup"
          isOn={isSignUp}
          leftLabel="Login"
          rightLabel="Sign Up"
          toggleSwitch={handleToggleSwich}
          toggleSwitchWithKeyboard={handleToggleSwitchWithKeyboard}
        />
      </HeaderContainer>
      <Portal>
        <FunctionalOverlay
          theme="fitness"
          isOpen={isOverlayOpen}
          toggleOverlay={toggleOverlay}
        >
          <HeadlineAndInformation
            headline={whyPasswordless.headline}
            information={whyPasswordless.information}
          />
        </FunctionalOverlay>
      </Portal>
    </>
  );
};
