import { BaseSVGProps } from "../../types"

export const GoogleIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 109 112"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path id="google-icon" fill="none" d="M0 0h108.872v111.389H0z" />
      <clipPath id="google_icon_clip1">
        <path
          id="g-blue"
          d="M107.535 56.843c0-3.843-.349-7.541-.988-11.089H55.492v20.968h29.172c-1.253 6.777-5.078 12.523-10.818 16.366v13.601h17.522c10.252-9.439 16.167-23.335 16.167-39.846z"
        />
      </clipPath>
      <g clipPath="url(#google_icon_clip1)">
        <path
          fill="#4385f4"
          fillRule="nonzero"
          d="M25.374 15.636h112.279v111.17H25.374z"
        />
      </g>
      <clipPath id="google_icon_clip2">
        <path
          id="g-green"
          d="M55.492 109.82c14.637 0 26.907-4.849 35.876-13.131L73.846 83.088c-4.855 3.253-11.066 5.174-18.354 5.174-14.125 0-26.076-9.535-30.335-22.353H7.045v14.047c8.92 17.721 27.25 29.864 48.447 29.864z"
        />
      </clipPath>
      <g clipPath="url(#google_icon_clip2)">
        <path
          fill="#36a755"
          fillRule="nonzero"
          d="M-23.073 35.791h144.559v104.147H-23.073z"
        />
      </g>
      <clipPath id="google_icon_clip3">
        <path
          id="g-yellow"
          d="M25.157 65.909c-1.084-3.253-1.704-6.722-1.704-10.3 0-3.572.62-7.048 1.704-10.301v-14.04H7.045A54.125 54.125 0 001.28 55.609c0 8.752 2.09 17.028 5.765 24.347l18.112-14.047z"
        />
      </clipPath>
      <g clipPath="url(#google_icon_clip3)">
        <path
          fill="#fbbb00"
          fillRule="nonzero"
          d="M-28.838 1.15h84.113v108.924h-84.113z"
        />
      </g>
      <clipPath id="google_icon_clip4">
        <path
          id="g-red"
          d="M55.492 22.961c7.957 0 15.101 2.735 20.721 8.108L91.76 15.522C82.375 6.77 70.099 1.397 55.492 1.397c-21.197 0-39.527 12.149-48.447 29.871l18.112 14.04c4.259-12.812 16.21-22.347 30.335-22.347z"
        />
      </clipPath>
      <g clipPath="url(#google_icon_clip4)">
        <path
          fill="#ea4539"
          fillRule="nonzero"
          d="M-23.073-28.721h144.95V75.426h-144.95z"
        />
      </g>
    </svg>
  )
}
