import styled from "styled-components";

interface InformationProps {
  headline: string;
  information: string[];
}

const InformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  width: 100%;
`;

const InfoHeadline = styled.h3`
  font-size: 3.2rem;
  color: var(--paragraph-color);
`;

const InfoParagraph = styled.p`
  font-size: 1.8rem;
  color: var(--paragraph-color);
  line-height: 1.6;
`;

export const HeadlineAndInformation: React.FC<InformationProps> = ({
  headline,
  information,
}) => {
  const paragraphs = information.map((p, i) => {
    return <InfoParagraph key={i}>{p}</InfoParagraph>;
  });

  return (
    <InformationContainer>
      <InfoHeadline>{headline}</InfoHeadline>
      {paragraphs}
    </InformationContainer>
  );
};
