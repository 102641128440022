import styled from "styled-components";

import { EmailForm } from "./EmailForm";
import { SocialLogin } from "../SocialLoginSection";
import { MultiDirectionWithScaleTransition } from "../../../../../animations/transitions/MultiDirectionWithScaleTransition";
import { sizes } from "../../../../../styles/sizes";

interface LoginFormProps {
  isActive: boolean;
}

const FormContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  padding: 60px 12px 0 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 80px;
  justify-items: center;
  width: 100%;
  ${sizes.aboveMobile} {
    padding: 80px 0 0 0;
  }
`;

export const LoginForm: React.FC<LoginFormProps> = ({ isActive }) => {
  return (
    <MultiDirectionWithScaleTransition
      isActive={isActive}
      enterDirection="from_left"
      exitDirection="to_left"
    >
      <FormContainer>
        <EmailForm />
        <SocialLogin />
      </FormContainer>
    </MultiDirectionWithScaleTransition>
  );
};
