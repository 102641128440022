import Link from "next/link";
import styled from "styled-components";

import { DashboardIcon } from "../svgs/DashboardIcon";
import { focusStyles } from "../../styles/siteStyles";
import { sizes } from "../../styles/sizes";

const Button = styled.a`
  padding: 6px 12px;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 12px;
  justify-items: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--label-color, var(--color-purple-17));
  text-transform: uppercase;
  text-decoration: none;
  background: var(--background, var(--color-blue-6));
  border: none;
  border-radius: 8px;
  width: fit-content;
  height: 32px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4);
  transition: box-shadow 300ms ease-in-out;
  &:focus {
    ${focusStyles}
  }
  ${sizes.aboveIpadPro} {
    &:hover {
      box-shadow: var(--gradient-button-hover-purple-1);
    }
  }
`;

const Icon = styled(DashboardIcon)`
  width: 20px; ;
`;

export const DashboardButton: React.FC = ({ children }) => {
  return (
    <Link href="/dashboard" passHref={true}>
      <Button>
        <Icon />
        {children}
      </Button>
    </Link>
  );
};
