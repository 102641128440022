import { Transition } from "react-transition-group"
import { gsap, Power3 } from "gsap"

interface NavTransitionProps {
  isOpen: boolean
}

export const LoginNavigationTransition: React.FC<NavTransitionProps> = ({
  isOpen,
  children,
}) => {
  const menuEnter = (node: HTMLElement): void => {
    if (isOpen) {
      gsap.fromTo(
        node,
        {
          y: "100%",
          autoAlpha: 0,
        },
        {
          duration: 0.4,
          y: 0,
          autoAlpha: 1,
          ease: Power3.easeOut,
        }
      )
    }
  }

  const menuExit = (node: HTMLElement): void => {
    if (!isOpen) {
      gsap.to(node, {
        duration: 0.4,
        y: "108%",
        autoAlpha: 0,
        ease: Power3.easeOut,
      })
    }
  }

  return (
    <Transition
      in={isOpen}
      timeout={1000}
      mountOnEnter={true}
      unmountOnExit={true}
      onEnter={(node: HTMLElement) => menuEnter(node)}
      onExit={(node: HTMLElement) => menuExit(node)}
    >
      {children}
    </Transition>
  )
}
