import React from "react";
import { useState, useEffect } from "react";

import { formValidator } from "../../utils/validation/formValidators";
import { emailValidationRules } from "../../utils/validation/rules";

export type InputValue = {
  value: string;
  valid: boolean;
};

export type InputOptions = {
  initial: boolean;
  touched: boolean;
};

export type InputName = "emailAddress";

export type UpdateValueFunction = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

export type UpdateOptionsFunction = (
  even: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

export const useEmailAddressForm = () => {
  const [emailAddress, setEmailAddress] = useState<InputValue>({
    value: "",
    valid: false,
  });

  const [emailAddressOptions, setEmailAddressOptions] = useState<InputOptions>({
    initial: true,
    touched: false,
  });

  const [clearData, setClearData] = useState<boolean>(false);

  useEffect(() => {
    if (clearData) {
      setEmailAddress({
        value: "",
        valid: false,
      });
      setEmailAddressOptions({
        initial: true,
        touched: false,
      });
      setClearData(false);
    }
  }, [clearData]);

  const updateInputValues: UpdateValueFunction = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    const name = event.target.name as InputName;
    const value = event.target.value;

    switch (name) {
      case "emailAddress": {
        const data = value.toLowerCase();
        const valid = formValidator(data, emailValidationRules);
        setEmailAddress({
          value: data,
          valid: valid,
        });
        break;
      }
      default: {
        throw new Error("You did not handle every input value type.");
      }
    }
  };

  const updateInputOptions: UpdateOptionsFunction = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    const name = event.target.name as InputName;

    switch (name) {
      case "emailAddress": {
        setEmailAddressOptions(({ touched }) => {
          return {
            initial: false,
            touched: !touched,
          };
        });
        break;
      }
      default: {
        throw new Error("You did not exhaust all input types.");
      }
    }
  };

  return {
    setClearData,
    emailAddress,
    emailAddressOptions,
    updateInputValues,
    updateInputOptions,
  };
};
