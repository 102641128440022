import { gsap, Power3, Linear } from "gsap";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

export const setProgressBarAni = (bar: HTMLDivElement) => {
  gsap.set(bar, { width: "7%" });
};

export const progressBarAni = (bar: HTMLDivElement, progress: number) => {
  gsap.to(bar, {
    width: `${progress}%`,
    ease: Linear.easeNone,
  });
};

export const formActiveIconAni = (
  iconArray: SVGCircleElement[],
  kill: boolean
) => {
  const tl = gsap.timeline({ repeat: -1 });

  if (kill) {
    tl.kill();
  }

  tl.to(iconArray, {
    duration: 0.5,
    autoAlpha: 0,
    ease: Linear.easeNone,
    stagger: 0.3,
  }).to(iconArray, {
    duration: 0.5,
    autoAlpha: 1,
    ease: Linear.easeNone,
    stagger: 0.3,
  });
};

// Error X out animations
export const setErrorAni = (left: SVGPathElement, right: SVGPathElement) => {
  gsap.set(left, { drawSVG: "0% 0%" });
  gsap.set(right, { drawSVG: "0% 0%" });
};

export const crossErrorAni = (
  left: SVGPathElement,
  right: SVGPathElement,
  kill: boolean
) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.to(right, {
    duration: 0.4,
    drawSVG: "100% 0%",
    ease: Power3.easeIn,
    reversed: false,
  }).to(
    left,
    {
      duration: 0.4,
      drawSVG: "100% 0%",
      ease: Power3.easeIn,
      reversed: false,
    },
    "-=0.2"
  );
};

export const setCheckmarkAni = (check: SVGPathElement) => {
  gsap.set(check, { drawSVG: "100% 0%", autoAlpha: 0 });
};

export const checkmarkAni = (check: SVGPathElement, kill: boolean) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    check,
    { drawSVG: "100% 0%", autoAlpha: 1 },
    {
      duration: 0.3,
      drawSVG: "100% 100%",
      ease: Power3.easeIn,
      autoAlpha: 1,
      reversed: true,
    }
  );
};

export const unCheckmarkAni = (check: SVGPathElement, kill: boolean) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    check,
    {
      drawSVG: "100% 100%",
    },
    {
      duration: 0.3,
      drawSVG: "0% 100%",
      ease: Power3.easeOut,
      reversed: true,
    }
  );
};

export const circleCheckAni = (path: SVGPathElement) => {
  gsap.fromTo(
    path,
    {
      drawSVG: "100% 100%",
    },
    {
      duration: 0.6,
      drawSVG: "0% 100%",
      ease: Linear.easeNone,
      delay: 0.4,
    }
  );
};
