import { useRouter } from "next/router";
import styled from "styled-components";
import { clientMagic } from "../../../../../../lib/magic";

import { SolidHeadlineStyle } from "../../../../../styles/typography";
import { SocialLoginButton } from "../../../../buttons/SocialLoginButton";
import { SocialIcon } from "../../../../../types";
import { sizes } from "../../../../../styles/sizes";

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 40px;
  justify-items: center;
  width: 100%;
  max-width: 360px;
  ${sizes.aboveMobile} {
    justify-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  justify-items: center;
  width: 100%;
`;

export const SocialLogin = () => {
  const { push } = useRouter();

  const handleSocialLogin = async (provider: SocialIcon) => {
    try {
      await clientMagic?.oauth.loginWithRedirect({
        provider: provider,
        redirectURI: new URL("/callback", window.location.origin).href,
        scope: ["user:email", "user:picture"],
      });
    } catch {
      console.log("Error loggingin with social account.");
    }
  };

  return (
    <SectionContainer>
      <h3>
        <SolidHeadlineStyle>Use Your Social Account:</SolidHeadlineStyle>
      </h3>
      <ButtonContainer>
        <SocialLoginButton
          socialIcon="google"
          handleSocialLogin={() => handleSocialLogin("google")}
        >
          Use Your Google Account
        </SocialLoginButton>
        <SocialLoginButton
          socialIcon="facebook"
          handleSocialLogin={() => handleSocialLogin("facebook")}
        >
          Use Your Facebook Account
        </SocialLoginButton>
        {/* <SocialLoginButton
          socialIcon="apple"
          handleSocialLogin={handleSocialLogin}
        >
          Use Your Apple Account
        </SocialLoginButton> */}
      </ButtonContainer>
    </SectionContainer>
  );
};
