import { useState } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { TextInput } from "../../../../forms/inputs/TextInput";
import { FormButton } from "../../../../buttons/FormButton/FormButton";
import { NetworkRequestContent } from "../../../../buttons/FormButton/NetworkRequestContent";
import { useEmailAddressForm } from "../../../../../hooks/forms/useEmailAddressForm";
import { fitnessForm } from "../../../../../styles/themes/formThemes";
import { LoginUserRes } from "../../../../../types/api";
import { userStore } from "../../../../../../lib/stores/userStore";

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  justify-items: center;
  width: 100%;
  max-width: 500px;
`;

export const EmailForm: React.FC = () => {
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);

  const { setUser, setIsUserLoggedIn } = userStore((state) => ({
    setUser: state.setUser,
    setIsUserLoggedIn: state.setIsUserLoggedIn,
  }));

  const {
    emailAddress,
    emailAddressOptions,
    updateInputValues,
    updateInputOptions,
    setClearData,
  } = useEmailAddressForm();

  const { push } = useRouter();

  const handleLoginWithEmail = async (event: React.FormEvent) => {
    event.preventDefault();

    const email = emailAddress.value;

    setIsLoggingIn(true);

    try {
      const clientMagic = (await import("../../../../../../lib/magic"))
        .clientMagic;
      const didToken = await clientMagic?.auth.loginWithMagicLink({
        email,
        redirectURI: new URL("/callback", window.location.origin).href,
      });

      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${didToken}`,
        },
      });

      if (res.status === 200) {
        setClearData(true);
        const userData: LoginUserRes = await res.json();
        const { username, avatar, isMember } = userData;

        if (username) {
          setUser({ username: username, isMember: isMember });
        }

        if (avatar) {
          setUser({ avatar: avatar, isMember: isMember });
        }
        setIsUserLoggedIn(true);
        push("/dashboard");
      }
    } catch (error) {
      setIsLoggingIn(false);
      setClearData(true);
      setIsLoginError(true);
    }
  };

  const formValid = emailAddress.valid;

  return (
    <>
      <FormContainer style={fitnessForm} onSubmit={handleLoginWithEmail}>
        <TextInput
          theme="fitness"
          inputType="email"
          name="emailAddress"
          labelFor="emailAddress"
          labelName="Email Address"
          labelError="Has to be valid email"
          labelInstructions="Enter email and let's go!"
          placeholder="Enter email address..."
          value={emailAddress.value}
          valid={emailAddress.valid}
          initial={emailAddressOptions.initial}
          touched={emailAddressOptions.touched}
          onChange={updateInputValues}
          onFocus={updateInputOptions}
          onBlur={updateInputOptions}
        />
        <FormButton
          buttonTheme="blue"
          sectionTheme="fitness"
          isValid={formValid && !isLoggingIn}
        >
          {formValid && !isLoggingIn ? (
            "Click To Login"
          ) : isLoggingIn ? (
            <NetworkRequestContent>Logging You In...</NetworkRequestContent>
          ) : (
            "Enter Email To Login"
          )}
        </FormButton>
      </FormContainer>
    </>
  );
};
