import { CSSProperties } from "react";

export const fitnessForm = {
  "--form-background": "var(--color-blue-10)",
  "--form-input-background": "var(--color-purple-16)",
  "--form-placeholder": "var(--color-purple-14)",
  "--form-input-text": "var(--color-blue-1)",
  "--form-caret": "var(--color-purple-4)",
  "--form-radio-check": "var(--color-purple-7)",
  "--form-radio-focus": "var(--color-blue-3)",
  "--form-button-background-invalid": "rgba(255,255,255,0.1)",
  "--form-progress-bar-background": "rgba(0, 0, 0, 0.7)",
  "--form-progress-bar": "var(--color-blue-3)",
} as CSSProperties;

export const nutritionForm = {
  "--form-background": "var(--color-purple-1)",
  "--form-input-background": "var(--color-white-1)",
  "--form-placeholder": "var(--color-purple-13)",
  "--form-input-text": "var(--color-purple-12)",
  "--form-caret": "var(--color-purple-9)",
  "--form-radio-check": "var(--color-blue-9)",
  "--form-radio-focus": "var(--color-purple-5)",
  "--form-button-background-invalid": "rgba(125,131,255,0.4)",
  "--form-progress-bar-background": "rgba(255, 255, 255, 0.7)",
  "--form-progress-bar": "var(--color-purple-5)",
} as CSSProperties;

// Form Element Themes

export const largeToggleDarkTheme = {
  "--switch-background-color": "hsl(240, 25%, 8%)",
  "--switch-knob-color": "var(--color-purple-8)",
  "--switch-hover-focus-box-shadow":
    "0 4px 2px 0px hsl(0, 0%, 6%), 0 0 0 2px hsl(0, 0%, 20%)",
} as CSSProperties;
