import { useState } from "react"
import styled from "styled-components"

import { LoginMoreMenuIcon } from "../../cssDrawings/LoginMenuIcon"
import { NavDrawer } from "./NavDrawer"
import { sizes } from "../../../styles/sizes"

const NavContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 200;
`

const ContentContainer = styled.div`
  position: relative;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  ${sizes.aboveMobile} {
    justify-content: flex-end;
  }
`

export const NavBar = () => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false)

  const toggleNavMenu = () => setIsNavOpen((prevValue) => !prevValue)

  return (
    <NavContainer>
      <ContentContainer>
        <LoginMoreMenuIcon
          isNavOpen={isNavOpen}
          toggleNavMenu={toggleNavMenu}
        />
        <NavDrawer isOpen={isNavOpen} toggleNavMenu={toggleNavMenu} />
      </ContentContainer>
    </NavContainer>
  )
}
