import { ColorTheme } from "../types";

export const appLoginNavList = [
  { id: 0, label: "Main Site", url: "https://fitwomensweekly.com" },
  {
    id: 1,
    label: "Privacy Policy",
    url: "https://fitwomensweekly.com/legal/privacy-policy",
  },
  {
    id: 2,
    label: "Disclaimer",
    url: "https://fitwomensweekly.com/legal/disclaimer",
  },
  {
    id: 3,
    label: "Terms",
    url: "https://fitwomensweekly.com/legal/terms-of-use",
  },
  { id: 4, label: "Contact", url: "https://fitwomensweekly.com/contact" },
];

interface UserAccountNavigation {
  id: number;
  theme: ColorTheme;
  label: string;
  slug: string;
}

export const userAccountNavigation: UserAccountNavigation[] = [
  {
    id: 0,
    theme: "blue",
    label: "Update My Avatar",
    slug: "/dashboard/user/update-avatar",
  },
  {
    id: 1,
    theme: "blue",
    label: "Create Username",
    slug: "/dashboard/user/update-username",
  },
  {
    id: 2,
    theme: "blue",
    label: "My Challenge Journal",
    slug: "/dashboard/user/my-journal",
  },
  {
    id: 3,
    theme: "blue",
    label: "Contact / Report Bug",
    slug: "/dashboard/user/contact",
  },
];
