import React, { useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";

import { HeaderSection } from "./HeaderSection";
import { SignUpForm } from "./FormSection/SignUpForm";
import { LoginForm } from "./FormSection/LoginForm";
import { NavBar } from "../../../navigation/loginView/NavBar";
import { RealKettlebellIcon } from "../../../svgs/RealKettlebellIcon";
import { DumbbellIcon } from "../../../svgs/DumbbellIcon";
import { DashboardButton } from "../../../buttons/DashboardButton";
import { clientMagic } from "../../../../../lib/magic";
import { PageStyleWrapper } from "../../../../styles/siteStyles";
import { fitnessDarkTheme } from "../../../../styles/themes/sectionThemes";
import { sizes } from "../../../../styles/sizes";

const ViewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
`;

const DashboardButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: fit-content;
  ${sizes.aboveTablet} {
    top: 40px;
    left: 50%;
    transform: translateX(-46%);
  }
`;

const Kettlebell = styled(RealKettlebellIcon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 260px;
  transform: translate(-40px, -20px) rotate(-10grad);
  ${sizes.aboveMobile} {
    width: 360px;
    transform: translate(-40px, -20px) rotate(10grad);
  }
  ${sizes.aboveTablet} {
    width: 520px;
    transform: translate(-40px, -20px) rotate(10grad);
  }
  ${sizes.aboveIpadPro} {
    width: 440px;
  }
`;

const Dumbbell = styled(DumbbellIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 360px;
  transform: translate(80px, -50px) rotate(-50grad);
  ${sizes.aboveMobile} {
    width: 440px;
  }
  ${sizes.aboveTablet} {
    width: 700px;
  }
  ${sizes.aboveIpadPro} {
    width: 500px;
  }
`;

export const LoginView = () => {
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const checkForUser = async () => {
      if (clientMagic) {
        const isUserLoggedIn = await clientMagic?.user.isLoggedIn();
        setIsUserLoggedIn(isUserLoggedIn);
      }
    };

    checkForUser();
  }, []);

  const handleToggleSwith = () => {
    setIsSignUp((prevValue) => !prevValue);
  };

  const handleToggleSwitchWithKeyboard = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setIsSignUp((prevValue) => !prevValue);
    }
  };

  const styles = {
    "--real-kettlebell-fill": "hsl(240, 25%, 12%)",
    "--dumbbell-fill": "hsl(240, 25%, 12%)",
    ...fitnessDarkTheme,
  } as CSSProperties;

  return (
    <PageStyleWrapper style={styles}>
      <ViewContainer>
        <ContentContainer>
          <HeaderSection
            isSignUp={isSignUp}
            handleToggleSwich={handleToggleSwith}
            handleToggleSwitchWithKeyboard={handleToggleSwitchWithKeyboard}
          />
          <FormContainer>
            <LoginForm isActive={!isSignUp} />
            <SignUpForm isActive={isSignUp} />
          </FormContainer>
        </ContentContainer>
        <NavBar />
        <Kettlebell />
        <Dumbbell />
        {isUserLoggedIn ? (
          <DashboardButtonContainer>
            <DashboardButton>Go To Dashboard</DashboardButton>
          </DashboardButtonContainer>
        ) : null}
      </ViewContainer>
    </PageStyleWrapper>
  );
};
