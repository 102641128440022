import { useEffect, useRef } from "react"

import {
  setCheckmarkAni,
  checkmarkAni,
  unCheckmarkAni,
} from "../../animations/forms"
import { BaseSVGProps } from "../../types"

interface CheckmarkIconProps extends BaseSVGProps {
  isTextInput: boolean
  isTrackingCheck: boolean
  runAction: boolean
}

export const CheckmarkIcon: React.FC<CheckmarkIconProps> = ({
  width,
  height,
  className,
  isTextInput,
  isTrackingCheck,
  runAction,
}) => {
  const checkRef = useRef<SVGPathElement | null>(null)

  useEffect(() => {
    const check = checkRef.current

    if (check && isTextInput) {
      setCheckmarkAni(check)
    }

    return () => {
      if (check) {
        checkmarkAni(check, true)
        unCheckmarkAni(check, true)
      }
    }
  }, [isTextInput])

  useEffect(() => {
    const check = checkRef.current

    if (check && isTextInput && runAction) {
      checkmarkAni(check, false)
    }

    if (check && isTextInput && !runAction) {
      unCheckmarkAni(check, false)
    }
  }, [runAction, isTextInput])

  useEffect(() => {
    const check = checkRef.current

    if (check && isTrackingCheck && runAction) {
      checkmarkAni(check, false)
    }

    if (check && isTrackingCheck && !runAction) {
      unCheckmarkAni(check, false)
    }
  }, [runAction, isTrackingCheck])

  return (
    <svg
      id="form-check"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 164.85 127.53"
    >
      <path
        style={{
          stroke: isTextInput ? "var(--color-green-1)" : "var(--color-white-1)",
        }}
        ref={checkRef}
        id="check"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="34"
        d="M147.85 17l-93.52 93.53L17 73.2"
      />
    </svg>
  )
}
