import styled from "styled-components";

import { LoadingKettlebell } from "../../svgs/LoadingKettlebell";

const ButtonContentContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  justify-items: center;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

const Kettlebell = styled(LoadingKettlebell)`
  width: 30px;
`;

export const NetworkRequestContent: React.FC = ({ children }) => {
  return (
    <ButtonContentContainer>
      <Kettlebell
        loaderType="start_training_plan"
        gradientId="networkRequestButton"
      />
      {children}
    </ButtonContentContainer>
  );
};
