import { CSSProperties } from "react";

import { SectionTheme } from "../../types";

interface FormReturnColors {
  labelStyle: CSSProperties;
  borderStyle: CSSProperties;
}

export const renderFormInputColors = (
  theme: SectionTheme,
  valid: boolean,
  initial: boolean,
  touched: boolean
): FormReturnColors => {
  const labelStyleFitness = {
    "--color":
      initial && !touched
        ? "var(--color-purple-7)"
        : touched
        ? "var(--color-purple-4)"
        : !touched && !valid
        ? "var(--color-pink-3)"
        : "var(--color-green-1)"
  } as CSSProperties;

  const labelStyleNutrition = {
    "--color":
      initial && !touched
        ? "var(--color-purple-9)"
        : touched
        ? "var(--color-purple-12)"
        : !touched && !valid
        ? "var(--color-pink-3)"
        : "var(--color-green-1)"
  } as CSSProperties;

  const borderStyleFitness = {
    "--border-color":
      initial && !touched
        ? "var(--color-blue-2)"
        : touched
        ? "var(--color-purple-4)"
        : !touched && !valid
        ? "var(--color-pink-3)"
        : "var(--color-green-1)"
  } as CSSProperties;

  const borderStyleNutrition = {
    "--border-color":
      initial && !touched
        ? "var(--color-purple-7)"
        : touched
        ? "var(--color-purple-12)"
        : !touched && !valid
        ? "var(--color-pink-3)"
        : "var(--color-green-1)"
  } as CSSProperties;

  const labelStyle =
    theme === "fitness" ? labelStyleFitness : labelStyleNutrition;

  const borderStyle =
    theme === "fitness" ? borderStyleFitness : borderStyleNutrition;

  return {
    labelStyle,
    borderStyle
  };
};
