import styled from "styled-components"

import { CheckmarkIcon } from "../../svgs/CheckmarkIcon"
import { FormActive } from "../../svgs/FormActive"
import { FormError } from "../../svgs/FormError"
import { FormMessageTransform } from "../../../animations/transitions/FormMessageTransition"
import { renderFormInputColors } from "../../../styles/functions/renderFormInputColors"
import { SectionTheme } from "../../../types"

interface TextInputProps {
  theme: SectionTheme
  inputType: string
  name: string
  labelName: string
  labelFor: string
  labelError: string
  labelInstructions: string
  placeholder: string
  value: string | number
  valid: boolean
  initial: boolean
  touched: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
}

const InputContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, min-content);
  gap: 12px;
  width: 100%;
  line-height: 0;
`

const InputLabel = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color);
  transform: translateX(24px);
`

const InputField = styled.input`
  margin: 0;
  padding: 12px 20px;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--form-input-text, var(--color-blue-11));
  background: var(--form-input-background, "none");
  border-color: var(--border-color);
  border-style: solid;
  border-width: 3px;
  border-radius: 20px;
  width: 100%;
  height: 60px;
  outline: none;
  caret-color: var(--form-caret, var(--color-blue-11));
  &::placeholder {
    font-size: 1.6rem;
    color: var(--form-placeholder, var(--color-white-3));
  }
`

const InputMessage = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--color);
  transform: translateY(-14px);
`

const ValidIcon = styled(CheckmarkIcon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  transform: translate(0px, -10px);
`

const ErrorIcon = styled(FormError)`
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  transform: translate(0px, -10px);
`

const ActiveIcon = styled(FormActive)`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  transform: translate(0px, -10px);
`

export const TextInput: React.FC<TextInputProps> = ({
  theme,
  inputType,
  name,
  labelName,
  labelFor,
  labelError,
  labelInstructions,
  placeholder,
  value,
  valid,
  initial,
  touched,
  onChange,
  onFocus,
  onBlur,
}) => {
  const cancelFullScreenKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }

  const { labelStyle, borderStyle } = renderFormInputColors(
    theme,
    valid,
    initial,
    touched
  )

  return (
    <InputContainer>
      <InputLabel style={labelStyle} htmlFor={labelFor}>
        {labelName}
      </InputLabel>

      {initial ? null : touched ? (
        <ActiveIcon />
      ) : !touched && valid ? (
        <ValidIcon
          isTextInput={true}
          isTrackingCheck={false}
          runAction={valid && !touched}
        />
      ) : (
        <ErrorIcon shouldAnimate={!valid && !touched} />
      )}

      <FormMessageTransform isActive={touched && !initial}>
        <InputMessage style={labelStyle}>{labelInstructions}</InputMessage>
      </FormMessageTransform>

      <FormMessageTransform isActive={!touched && !valid && !initial}>
        <InputMessage style={labelStyle}>{labelError}</InputMessage>
      </FormMessageTransform>

      <InputField
        style={borderStyle}
        type={inputType}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={cancelFullScreenKeyPress}
      />
    </InputContainer>
  )
}
