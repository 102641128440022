import styled, { css } from 'styled-components'

// Page Wrapper When You Know Sections Won't Change Themes
export const PageStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  line-height: 0;
`

// Base button styles
export const baseButtonStyles = css`
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  color: var(--button-label);
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  background: var(--button-background);
  border: none;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4);
  transition: box-shadow 300ms ease-in-out;
  &:hover {
    box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4), var(--button-hover);
  }
`;

export const focusStyles = css`
  outline: 2px solid var(--button-focus-outline);
	outline-offset: 4px;
`

// Spacers for views when you don't want to use padding
export const BottomViewSpacer = styled.div`
  width: 100%;
  height: 120px;
`

export const CoverImageOverlay = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background: hsla(240, 100%, 0%, 0.4);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const VideoButtonPlayEnhancer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background: hsla(240, 100%, 0%, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  filter: blur(12px);
  transform: translate(-6px, 4px);
  z-index: 1;
`
