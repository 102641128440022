import { FacebookIcon } from "./FacebookIcon";
import { GoogleIcon } from "./GoogleIcon";
import { AppleIcon } from "./AppleLogo";
import { SocialIcon } from "../../../../types";

interface RenderIconProps {
  socialIcon: SocialIcon;
}

export const RenderIcon: React.FC<RenderIconProps> = ({ socialIcon }) => {
  const renderIcon = () => {
    switch (socialIcon) {
      case "google": {
        return <GoogleIcon />;
      }
      case "facebook": {
        return <FacebookIcon />;
      }
      case "apple": {
        return <AppleIcon />;
      }
      default: {
        throw new Error("You have not provided for all social icons.");
      }
    }
  };

  return <>{renderIcon()}</>;
};
