import { BaseSVGProps } from "../../types";

export const DumbbellIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 200 86"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path id="dumbbell-icon" fill="none" d="M0 0h200v85.611H0z" />
      <path
        style={{ fill: "var(--dumbbell-fill, var(--color-white-1))" }}
        id="dumbbell-body"
        d="M3.1 22.333L17.092 1.985l37.449 1.007 8.496 11.556 1.122 20.348 71.028.327 1.204-20.164 8.877-11.289 36.675.448 14.672 20.498 1.089 31.135-4.715 14.932-9.427 12.647-36.814.269-8.351-10.662-2.406-8.492-.61-13.032-71.168-.318-.174 7.197-1.867 11.957-9.231 12.603-38.939-.878-7.736-10.687-3.97-16.528.804-32.526z"
      />
    </svg>
  );
};
